import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const authToken = this.authService.getAuthorizationToken();

        if (!authToken) {
            this.router.navigate(['app/login']);
        } else {
            // Clone the request and replace the original headers with cloned headers, updated with the authorization token
            const authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authToken)
            });
            // send cloned request with header to the next handler.
            return next.handle(authReq);
        }
        return next.handle(req);

        // Since we are using the Flask server for all CORS we don't need 
        // If the request is for outside the domain, do not add the token
        //if (!req.url.match(/northplainsgcd.mapfeeder.net\//)) {
        //    return next.handle(req);
        //}
    }
}