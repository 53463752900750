import {Component, OnInit, OnDestroy} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { DEFAULT_PRODUCTION_YEAR, GCP_ANALYTICS_MEASUREMENT_ID } from './env';
import { AuthService } from './services/auth/auth.service';

import {PortalApiService} from './services/portal-api/portal-api.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = String(DEFAULT_PRODUCTION_YEAR) + ' Production Year';
    unsubscribe: Subject<any> = new Subject();

    constructor(
        private titleService: Title,
        private authService: AuthService,
        private portalApiService: PortalApiService,
        private router: Router) {

        combineLatest([this.portalApiService.userData, this.router.events])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(result => {
                if (result[0] != null) {
                    let userInfo = result[0];
                    gtag('js', new Date());
                    gtag('config', GCP_ANALYTICS_MEASUREMENT_ID,
                        {
                            'custom_map': {
                                'dimension1': 'user_name',
                                'dimension2': 'user_email',
                                'dimension3': 'company'
                            }
                        }
                    );
                    gtag('event', 'portal_view_page_load', { 'user_name': String(userInfo.full_name), 'user_email': String(userInfo.email), 'company': String(userInfo.company) });
                }
            });
    }

    ngOnInit() {
        this.titleService.setTitle(this.title);
    }

  ngOnDestroy() {}

}