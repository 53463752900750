<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<style>
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');
</style>

<router-outlet></router-outlet>