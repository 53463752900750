import { Component, OnInit } from '@angular/core';
import { SUPPORT_PHONE_NUMBER } from '../env';
import { AlertMessage } from '../interfaces/alert.interface';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    inPhoneNumberVisible: string = '';
    inPhoneNumber: string = '';
    inPasscode: string = '';
    visEnterPhoneNumber: boolean = true;
    visEnterPassCode: boolean = false;
    alertMessages: AlertMessage[] = [];
    alertTextContactSupport: string = `If further assistance is needed contact the support number ${SUPPORT_PHONE_NUMBER} for the North Plains office.`;

    constructor(private authService: AuthService) { }

    ngOnInit(): void { }

    userPhoneNumberInputChange() {
        this.inPhoneNumber = String(this.inPhoneNumberVisible).replace(/\D/g, "");
        if (this.inPhoneNumber.slice(3, 6) != '') {
            this.inPhoneNumberVisible = this.inPhoneNumber.slice(0, 3) + "-" + this.inPhoneNumber.slice(3, 6);
        }

        if (this.inPhoneNumber.slice(6) != '') {
            this.inPhoneNumberVisible += "-" + this.inPhoneNumber.slice(6);
        }
    }

    userLogin() {
        let promise = this.authService.login(this.inPhoneNumber, this.inPasscode);
        promise.then((result) => {
            if (result.status == 200) {
                window.sessionStorage['userPhoneNumber'] = String(this.inPhoneNumber);
                this.visEnterPhoneNumber = false;
                this.visEnterPassCode = false;
                location.href = 'app/view';
            } else {
                let msgText = 'Error logging in user';
                if (result.error.message != undefined) {
                    msgText = String(result.error.message);
                }
                msgText = msgText + '\n' + this.alertTextContactSupport;
                this.alertMessages = [];
                this.alertMessages.push({ type: 'danger', text: msgText });
            }
        });
    }

    sendCode() {
        let promise = this.authService.sendCode(this.inPhoneNumber);
        promise.then((result) => {
            let msgType = 'danger';
            let msgText = 'Error sending code';
            if(result.status == 200){
                this.visEnterPhoneNumber = false;
                this.visEnterPassCode = true;
                msgType = 'success';
                if(result.body.message != undefined){
                    msgText = String(result.body.message);
                }
            }else{
                if(result.error.message != undefined){
                    msgText = String(result.error.message);
                }
                msgText = msgText + '\n' + this.alertTextContactSupport;
            }
            this.alertMessages = [];
            this.alertMessages.push({type:msgType, text: msgText});
        });
    }
}
