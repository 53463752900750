import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {MeterChangeRequest, ProductionReportDownloadRequest} from '../interfaces/portal-api.interface';
import { PortalApiService } from '../services/portal-api/portal-api.service';
import { ModalComponent } from 'ngb-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';
import { FORM_SERVICE_URL } from '../env';

@Component({
  selector: 'app-download-production-report',
  templateUrl: './download-production-report.component.html',
  styleUrls: ['./download-production-report.component.scss']
})
export class DownloadProductionReportComponent {

    unsubscribe: Subject<any> = new Subject();

    list_meter_change_type: any;
    list_meter_type: any;
    list_meter_change_status: any;
    list_meter_location: any;
    list_meter_change_reason: any;
    list_meter_status: any;

    public myContent: any;
    public production_id: number;
    public meter_number_array: Array<string>;
    public isReportDownloading = false;

    @ViewChild(ModalComponent) ModalComponent: any;

    mcRequest: ProductionReportDownloadRequest;
    alertMessages: Array<any>;
    modalVisible = false;

    constructor(
        private portalApiService: PortalApiService,
        public modalRef: BsModalRef
    ) {
        this.mcRequest = {production_id: -1, template_send: 'downloadnow', template_path: ''};
        this.production_id = -1;
        this.meter_number_array = [];
        this.alertMessages = [];
        this.modalVisible = true;

        this.portalApiService.template_list
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.list_meter_change_type = data;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    async save() {
        this.isReportDownloading = true;
        this.mcRequest.production_id = this.modalRef.content.production_id;
        this.mcRequest.pid_values_array = [this.modalRef.content.production_id]
        this.mcRequest.record = await firstValueFrom(this.portalApiService.getRecord(this.modalRef.content.gpu, this.modalRef.content.year))

        let promise = this.portalApiService.submitProductionReportDownloadRequest(this.mcRequest);
        promise.then((value) => {
            console.log(value);
            const dlpath = value.download_path.substring(1); // remove the first character '/'
            window.open(`${FORM_SERVICE_URL}${dlpath}`, '_blank', '');
        }).catch((response) => {
            if (response.error) {
                this.alertMessages.push({ type: 'danger', text: response.error.message });
            }
        }).finally(() => {
            this.isReportDownloading = false;
            this.modalRef.hide();
            this.portalApiService.getGPUProductionForID(String(this.mcRequest.production_id));
        });
    }
}
