import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { GPUsPageComponent } from './gpus-page/gpus-page.component';
import { LoginGuard } from './guards/login.guard';


const routes: Routes = [
    { path: '', component: GPUsPageComponent },
    { path: 'app', component: GPUsPageComponent },
    { path: 'app/login', component: LoginPageComponent },
    { path: 'app/view', component: GPUsPageComponent, canActivate: [LoginGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
