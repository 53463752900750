import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';
//import { ModalManager, ModalModule, ModalComponent } from 'ngb-modal';
//import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { saveAs } from 'file-saver';
import {firstValueFrom, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from "lodash";
import { ViewEncapsulation } from '@angular/core';
import {
    DEFAULT_PRODUCTION_YEAR,
    LIST_METER_TYPE_ID_INCLUDE_GPM,
    SUPPORT_EMAIL,
    SUPPORT_PHONE_NUMBER,
    LIST_VALID_UPLOAD_FILE_TYPES,
    FILE_UPLOAD_MAX_SIZE_IN_BYTES,
    LIST_METER_TYPE_ID_REQUIRE_FILE_UPLOAD,
    FORM_SERVICE_URL
} from '../env';
import { MeterChangeRequest, ProductionStatus } from '../interfaces/portal-api.interface';
import { AuthService } from '../services/auth/auth.service';
import { PortalApiService } from '../services/portal-api/portal-api.service';
import { MeterChangeRequestComponent } from '../meter-change-request/meter-change-request.component';
import { DownloadProductionReportComponent } from '../download-production-report/download-production-report.component';


@Component({
    selector: 'app-gpus-page',
    templateUrl: './gpus-page.component.html',
    styleUrls: ['./gpus-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GPUsPageComponent implements OnInit {

    public searchYear: number;
    public supportPhoneNumber;
    public supportEmail;

    public gpusList: any[];
    public inSearch: string | undefined;
    public inStatus: string | undefined;

    public gpuStatusList: any[];
    public gpuStatusFilterSelectedText: string = "Status";

    public meterTypeIDsIncludeGPM: number[];
    public meterTypeIDsRequireFileUpload: number[];

    unsubscribe: Subject<any> = new Subject();
    ProductionStatus: typeof ProductionStatus;
    userName: string;

    maxFileSize: string;
    allowedFileFormats: string[];
    uploadOperationWaiting: boolean;
    viewSearchMeterSerialNumber: boolean;
    toggleSearchButtonText: string;
    searchMeterSerialNumberValue: string;

    modalRef: BsModalRef | undefined;

    @ViewChild('myModal') myModal: any;

    constructor(
        private modalService: BsModalService,
        //private modalService: ModalManager,
        private authService: AuthService,
        private portalApiService: PortalApiService
    ) {
        this.userName = '';
        this.gpusList = [];
        this.gpuStatusList = [];
        this.searchYear = DEFAULT_PRODUCTION_YEAR;
        this.supportPhoneNumber = SUPPORT_PHONE_NUMBER;
        this.supportEmail = SUPPORT_EMAIL;

        this.ProductionStatus = ProductionStatus;

        // TODO - convert this to an enum type object
        this.gpuStatusList.push({ 'id': 0, 'text': 'None' }, { 'id': 13, 'text': 'Approved' }, { 'id': 12, 'text': 'Submitted' }, { 'id': 11, 'text': 'In Progress' }, { 'id': 10, 'text': 'Online (Awaiting)' });
        this.meterTypeIDsIncludeGPM = LIST_METER_TYPE_ID_INCLUDE_GPM;
        this.meterTypeIDsRequireFileUpload = LIST_METER_TYPE_ID_REQUIRE_FILE_UPLOAD;

        this.maxFileSize = String(FILE_UPLOAD_MAX_SIZE_IN_BYTES / 1048576) + 'MB';
        this.allowedFileFormats = LIST_VALID_UPLOAD_FILE_TYPES;
        this.uploadOperationWaiting = false;
        this.viewSearchMeterSerialNumber = false;
        this.toggleSearchButtonText = 'Toggle Search';
        this.searchMeterSerialNumberValue = '';

    }

    ngOnDestroy() {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    ngOnInit(): void {
        this.portalApiService.gpusListDataObservable
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.gpusList = data;
                    // Evaluate each GPU and determing if they are ready to submit
                    this.evaluateGPUList();
                }
            });
        this.portalApiService.getUserGPUs();

        this.portalApiService.userNameDataObservable
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.userName = data;
                }
            });
        this.portalApiService.getUserPersonInfo(String(window.sessionStorage['userPhoneNumber']));

    }



    // Should also check for PIDs since they might have values but not saved yet
    doMetersNeedInput(meters: [any]): boolean {
        return meters.reduce((previousMeterNeedsInput, meter) => {
            if (previousMeterNeedsInput)
                return previousMeterNeedsInput;
            return this.doInspectionsNeedInput(meter.inspections);
        }, false);
    }

    doInspectionsNeedInput(inspections: [any]): boolean {
        return inspections.reduce((previousInspectionNeedsInput, currentInspection) => {
            if (previousInspectionNeedsInput)
                return previousInspectionNeedsInput;

            if (currentInspection.reporting_year === this.searchYear
                && currentInspection.ignore_reading !== true
                && currentInspection.visible_to_producer === true
            ) {
                if (currentInspection.meter_reading === null
                    || currentInspection.meter_reading === ''
                    || !currentInspection.meter_reading
                ) {
                    return true;
                }
                if (!currentInspection.meter_inspection_id) {
                    return true;
                }
            }
        }, false);
    }

    evaluateGPUList() {
        this.gpusList.forEach(gpu => {
            if (gpu.production_data && gpu.production_data.meter) {
                gpu.list_production_status_is_ready_to_submit = !this.doMetersNeedInput(gpu.production_data.meter.meters);
            } else {
                gpu.list_production_status_is_ready_to_submit = false;
            }
        });
    }

    onProductionHeaderClicked($event: any, production_id: any) {
        console.log('onProductionHeaderClicked');
        $event.stopPropagation();
        let target = $event.target;
        console.log(target);
        if (target.getAttribute('aria-expanded') == 'false') {
            return; // no need to re generate the contents of the modal when closing.
        }
        this.portalApiService.getGPUProductionForID(production_id);
    }

    onSearchChange(event: any) {
        let val = event.target.value;
        this.portalApiService.updateGPUsListDataForSearchString(val);
    }

    onStatusFilterChange(event: any) {
        let val = event.target.value;
        this.gpuStatusFilterSelectedText = val;
        this.portalApiService.updateGPUsListDataForStatus(val);
    }

    meterInspectionRowChange(event: any, gpuProd:any, meter: any, meter_inspection: any) {
        delete meter_inspection.error_message_meter_reading;
        delete meter_inspection.error_message_gpm;
        delete meter_inspection.error_message_acre_feet;

        // if it is a new meter inspection then remove the dirty flag if the gpm is also not set
        if (meter_inspection.meter_inspection_id == undefined) {
            if (meter_inspection.meter_reading == null) {
                if (this.meterTypeIDsIncludeGPM.includes(meter.list_meter_type_id)) {
                    // then we need to check it
                    if (meter_inspection.gpm == null) {
                        meter_inspection.record_is_dirty = false;
                        return;
                    }
                } else {
                    meter_inspection.record_is_dirty = false;
                    return;
                }
            }
        }

        if (meter_inspection.meter_reading !== null) {
            if (Number.isNaN(parseFloat(meter_inspection.meter_reading)) || (/[a-z]/i.test(meter_inspection.meter_reading))) {
                let msg = 'Meter reading value must be positive integer.';
                meter_inspection.error_message_meter_reading = msg;
            }
        }

        let newMeterReadingValueDifference = parseFloat(meter_inspection.meter_reading) - parseFloat(meter_inspection.initial_meter_reading);
        if (newMeterReadingValueDifference < 0) {
            let msg = `The Meter Reading ${meter_inspection.meter_reading} is less than the Initial Reading of ${meter_inspection.initial_meter_reading}`
            meter_inspection.error_message_meter_reading = msg;
        }

        if ((meter_inspection.gpm < 0) || (Number.isNaN(parseFloat(meter_inspection.gpm))) || (/[a-z]/i.test(meter_inspection.gpm))) {
            let msg = 'GPM value must be positive integer.';
            meter_inspection.error_message_gpm = msg;
        }

        meter_inspection.sub_total = newMeterReadingValueDifference;
        meter_inspection.acre_feet = meter_inspection.sub_total * meter_inspection.gpm * meter.multiplier;
        if (Number.isNaN(meter_inspection.sub_total)) {
            meter_inspection.acre_feet = ''; // Do not display NaN to the user.
        }
        if (meter_inspection.acre_feet < 0) {
            let msg = 'Acre Feet value must be positive.';
            meter_inspection.error_message_acre_feet = msg;
        }
        gpuProd.list_production_status_is_ready_to_submit = false;
        meter_inspection.record_is_dirty = true;
    }

    submitGPUProductionReport(event: any, gpuProductionRecord: any) {

        let metersWithInspectionsListCopy = cloneDeep(gpuProductionRecord.production_data.meter.meters);
        metersWithInspectionsListCopy.forEach((object: { [x: string]: any; }) => {
            object['inspections'].forEach((item: any, index: number, arr: any) => {
                delete item['fileFormForFileData'];
                delete item['filesUploadedList'];
            });
        });

        const metersWithInspectionsList = metersWithInspectionsListCopy.filter((meter: { inspections: any[]; }) => {
            // get only edited inspections
            meter.inspections = meter.inspections.filter(inspection => inspection.record_is_dirty == true).filter(inspection => inspection.reporting_year == this.searchYear);
            // get meters with edited inspections
            return meter.inspections.length > 0;
        });

        // TODO - Check for these serverside as well
        let cancelSave = false;
        // Check for required uploads for certain meter types
        let meters = gpuProductionRecord.production_data.meter.meters;
        for (let i = 0; i < meters.length; i++) {
            let aMeter = meters[i];
            aMeter.alertMessages = [];
            if (this.meterTypeIDsRequireFileUpload.includes(aMeter.list_meter_type_id)) {
                for (let s = 0; s < aMeter.inspections.length; s++) {
                    let aMInspection = aMeter.inspections[s];
                    if ((aMInspection.reporting_year === this.searchYear) && (aMInspection.visible_to_producer === true) && (aMInspection.ignore_reading !== true)) {
                        if ((!aMInspection.filesUploadedList) || (aMInspection.filesUploadedList.length == 0)) {
                            aMeter.alertMessages.push({ type: 'danger', text: 'File upload required for meter type ' + String(aMeter.meter_type) });
                            event.target.closest('.accordion-item').querySelector('.input-error-text').scrollIntoView();
                            cancelSave = true;
                        }
                    }
                }
            }
        }
        if (cancelSave == true) {
            return;
        }

        gpuProductionRecord.operation_currently_running = true;

        const dataObj = {'production_id': gpuProductionRecord['production_id'] };
        let savePromise = this.portalApiService.submitProduction(dataObj);
        event.target.setAttribute('disabled', true);
        savePromise.then((value) => {
            // re-query the datastore so the year production total updates.
            this.portalApiService.getGPUProductionForID(gpuProductionRecord.production_id);
            firstValueFrom(this.portalApiService.getRecord(gpuProductionRecord.gpu, gpuProductionRecord.year))
                .then((record) => {
                    return this.portalApiService.submitProductionReportDownloadRequest({
                        production_id: gpuProductionRecord.production_id,
                        pid_values_array: [gpuProductionRecord.production_id],
                        record: record,
                        template_send: 'downloadnow',

                        /*
                         * hard-codeing template path because we do not have a way to detect which report is the "filled out"
                         * version. At some point we could probably add an endpoint on Banyan that explicitly configures which
                         * template to use, but we don't have that now.
                         *
                         * If this breaks, check app.app_config_edit.resource_template and make sure the template paths have
                         * not changed for the production_all endpoint.
                         */
                        template_path: 'program-templates/production/Production Report Producer Portal.docx',
                    }).then((value) => {
                        console.log(value);
                        const dlpath = value.download_path.substring(1); // remove the first character '/'
                        window.open(`${FORM_SERVICE_URL}${dlpath}`, '_blank', '');
                    });
            });
        }).catch((response) => {
            console.log('Error', response);
            if (response.error) {
                gpuProductionRecord.alertMessages = [];
                gpuProductionRecord.alertMessages.push({ type: 'danger', text: String(response.error.message) + ' Please contact district for assistance.' });
            }
        }).finally(() => {
            event.target.removeAttribute('disabled');
            delete gpuProductionRecord.operation_currently_running;
        });
    }

    saveMeterInspections(event: any, gpuProductionRecord: any) {
        if (!gpuProductionRecord.production_data.meter) {
            gpuProductionRecord.alertMessages = [];
            gpuProductionRecord.alertMessages.push({ type: 'danger', text: 'This Production record contains no meters. There is nothing to save.' });
            return;
        }
        let metersWithInspectionsListCopy = cloneDeep(gpuProductionRecord.production_data.meter.meters);
        metersWithInspectionsListCopy.forEach((object: { [x: string]: any; }) => {
            object['inspections'].forEach((item:any, index:number, arr:any) => {
                delete item['fileFormForFileData'];
                delete item['filesUploadedList'];
            });
        });

        const metersWithInspectionsList = metersWithInspectionsListCopy.map((meter) => {
            const isDeductMeter = meter.list_deduct_meter === 1;
            meter.inspections.map((inspection) => {
                const acreFeet = parseFloat(inspection.acre_feet);
                if (isDeductMeter && !isNaN(acreFeet)) {
                    inspection.acre_feet =  -Math.abs(acreFeet);
                }
               return inspection;
            });
            return meter;
        }).filter((meter: { inspections: any[]; }) => {
            // get only edited inspections
            meter.inspections = meter.inspections.filter(inspection => inspection.record_is_dirty == true).filter(inspection => inspection.reporting_year == this.searchYear);
            // get meters with edited inspections
            return meter.inspections.length > 0;
        });

        for (let m = 0; m < gpuProductionRecord.production_data.meter.meters.length; m++) {
            const aMeter = gpuProductionRecord.production_data.meter.meters[m];

            aMeter.alertMessages = [];
            for (let i = 0; i < aMeter.inspections.length; i++) {
                const aMInspection = aMeter.inspections[i];
                if (aMInspection.error_message_meter_reading) {
                    aMeter.alertMessages.push({ type: 'danger', text: 'Cannot save Report with invalid Meter Reading(s)' });
                    event.target.closest('.accordion-item').querySelector('.input-error-text').scrollIntoView();
                    return;
                }
                if (aMInspection.error_message_gpm) {
                    aMeter.alertMessages.push({ type: 'danger', text: 'Cannot save Report with invalid GPM value' });
                    event.target.closest('.accordion-item').querySelector('.input-error-text').scrollIntoView();
                    return;
                }
            }
        }

        const dataObj = { 'meters': metersWithInspectionsList, 'production_id': gpuProductionRecord['production_id'] };

        event.target.setAttribute('disabled', true);

        gpuProductionRecord.operation_currently_running = true;

        let savePromise = this.portalApiService.saveMeterInspections(dataObj);
        savePromise.then((value) => {
            this.portalApiService.getGPUProductionForID(gpuProductionRecord.production_id);
        }).catch((response) => {
            console.log('Error', response);
            if (response.error) {
                gpuProductionRecord.alertMessages = [];
                gpuProductionRecord.alertMessages.push({ type: 'danger', text: String(response.error.message) + ' Please contact district for assistance.' });
                event.target.closest('.accordion-item').scrollIntoView();
            }
        }).finally(() => {
            event.target.removeAttribute('disabled');
            delete gpuProductionRecord.operation_currently_running;
        });
    }

    uploadDocument(event: any, gpuProd: any, meter: any, meter_inspection: any) {
        let file_form: FileInput = meter_inspection.fileFormForFileData.get('file')!.value as FileInput;
        const file = file_form.files[0];
        const fileExt = '.' + String(file.name.split('.').pop());
        if (!LIST_VALID_UPLOAD_FILE_TYPES.includes(fileExt)) {
            if (!meter.alertMessages) {
                meter.alertMessages = [];
            }
            const msg = 'File type (' + fileExt + ') is not allowed';
            meter.alertMessages.push({ type: 'danger', text: msg + ' Please contact district for assistance.' });
            return;
        }
        if (file.size > FILE_UPLOAD_MAX_SIZE_IN_BYTES) {
            if (!meter.alertMessages) {
                meter.alertMessages = [];
            }
            const msg = 'File size is too large, maximum upload size is ' + this.maxFileSize;
            meter.alertMessages.push({ type: 'danger', text: msg + ' Please contact district for assistance.' });
            return;
        }
        event.target.setAttribute('disabled', true);

        const formData = new FormData();
        formData.append('files', file);
        formData.append('meter_id', meter.meter_id);
        formData.append('meter_number', meter.meter_number);
        formData.append('meter_inspection_id', meter_inspection.meter_inspection_id);
        let promise = this.portalApiService.uploadMeterFile(formData);
        promise.then((value) => {
            this.portalApiService.getFilesForMeter(gpuProd.production_id, meter.meter_id, meter_inspection.meter_inspection_id);
        }).catch((response) => {
            console.log('Error', response);
            if (response.error) {
                if (!meter.alertMessages) {
                    meter.alertMessages = [];
                }
                meter.alertMessages.push({ type: 'danger', text: String(response.error.message) + ' Please contact district for assistance.' });
                event.target.closest('.accordion-item').scrollIntoView();
            }
        }).finally(() => {
            event.target.removeAttribute('disabled');
        });
    }
    deleteFileClicked(event: any, gpuProd: any, meter: any, meter_inspection: any, meterFile: any) {
        event.stopImmediatePropagation();
        if (! confirm('Are you sure you want to permanently delete this file?')){
            return;
        }
        const formData = new FormData();
        formData.append('gcp_file_path', meterFile.gcp_file_path);
        let promise = this.portalApiService.deleteMeterFile(formData);
        promise.then((value) => {
            this.portalApiService.getFilesForMeter(gpuProd.production_id, meter.meter_id, meter_inspection.meter_inspection_id);
        }).catch((response) => {
            console.log('Error', response);
            if (response.error) {
                if (!meter.alertMessages) {
                    meter.alertMessages = [];
                }
                meter.alertMessages.push({ type: 'danger', text: String(response.error.message) + ' Please contact district for assistance.' });
                event.target.closest('.accordion-item').scrollIntoView();
            }
        });
    }

    fileRowClicked(event: any, meter: any, meter_inspection: any, meterFile: any) {
        const fileNameFromPath = meterFile.name.replace(/^.*[\\\/]/, '');
        const formData = new FormData();
        formData.append('gcp_file_path', meterFile.gcp_file_path);
        let res = this.portalApiService.downloadFile(formData);
        res.subscribe(
            data => { saveAs(data, fileNameFromPath)},
            err => {
                console.log("There was a problem downloading the file.");
                console.error(err);
            }
        );
    }

    toggleSearch() {
        this.portalApiService.updateGPUsListDataForSearchString('');
        this.gpuStatusFilterSelectedText = 'None';
        this.searchMeterSerialNumberValue = '';
    }

    searchMeterSerialNumber(event: any) {
        this.portalApiService.searchBasedOnMeterSerial(this.searchMeterSerialNumberValue);
    }

    openMeterChangeRequestModal($event: any, gpuProd: any) {
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        console.log('openMeterChangeRequestModal()');
        console.log(gpuProd);

        if (gpuProd.production_data == undefined) {
            console.log('You must wait for the production record to load before openening the meter change request form edit modal.');
            return;
        }

        let meters_array = gpuProd.production_data.meter.meters;
        let meter_number_arr = meters_array.map(m => m.meter_number);

        this.modalRef = this.modalService.show(MeterChangeRequestComponent, { ariaLabelledBy: 'Meter Change Request', class: 'modal-lg' }); // modal-xl
        //this.modalRef.content.myContent = 'My Modal Content';
        this.modalRef.content.meter_number_array = meter_number_arr;
        this.modalRef.content.production_id = gpuProd.production_id;
    }

    openDownloadProductionReportModal($event: any, gpuProd: any) {
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        console.log('openDownloadProductionReportModal()');
        console.log(gpuProd);

        if (gpuProd.production_data == undefined) {
            console.log('You must wait for the production record to load before openening the meter change request form edit modal.');
            return;
        }

        // let meters_array = gpuProd.production_data.meter.meters;
        // let meter_number_arr = meters_array.map(m => m.meter_number);

        this.modalRef = this.modalService.show(DownloadProductionReportComponent, { ariaLabelledBy: 'Download Production Report', class: 'modal-lg' }); // modal-xl
        //this.modalRef.content.myContent = 'My Modal Content';
        // this.modalRef.content.meter_number_array = meter_number_arr;
        this.modalRef.content.production_id = gpuProd.production_id;
        this.modalRef.content.gpu = gpuProd.gpu;
        this.modalRef.content.year = gpuProd.year;
    }
}
