import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
//import { ModalModule } from 'ngb-modal';
//import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth-interceptor';
import { GPUsPageComponent } from './gpus-page/gpus-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { AuthService } from './services/auth/auth.service';
import { PortalApiService } from './services/portal-api/portal-api.service';
import { MeterChangeRequestComponent } from './meter-change-request/meter-change-request.component';
import { DownloadProductionReportComponent } from './download-production-report/download-production-report.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent,
        GPUsPageComponent,
        MeterChangeRequestComponent,
        DownloadProductionReportComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MaterialFileInputModule,
        MatSelectModule,
        MatInputModule,
        NgxSkeletonLoaderModule,
        ModalModule.forRoot()
        //BsModalService
        //NgbModule
    ],
    providers: [
        PortalApiService,
        BsModalService,
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    entryComponents: [MeterChangeRequestComponent]
})
export class AppModule { }
