import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { API_URL } from '../../env';

@Injectable({
    providedIn: 'root'
})
export class AuthService{

    private token: any;

    constructor(
        private http: HttpClient
    ) {
        this.token = '';
    }


    sendCode(phoneNumber: string) {
        var formData = new FormData();
        formData.append("phone", String(phoneNumber));
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/sendcode`, formData, { observe: 'response' })
                .toPromise()
                .then((response: any) => resolve(response), (error: any) => resolve(error));
        });
        return promise;
    }

    login(inPhone: string, inPasscode: string) {
        var formData = new FormData();
        formData.append("phone", inPhone);
        formData.append("passcode", inPasscode);
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/login`, formData, { observe: 'response' })
                .toPromise()
                .then((response: any) => resolve(response), (error: any) => resolve(error));
        });

        promise.then((result) => {
            if (result.status == 200) {
                let resJWT = result.body.jwt.token;
                window.sessionStorage['token'] = resJWT;
                this.token = resJWT;
            } else {
                console.log('Error : ' + String(result));
            }
        });
        return promise;
    }

    logout() {
        delete window.sessionStorage['token'];
    }

    getAuthorizationToken() {
        return window.sessionStorage['token'] ? window.sessionStorage['token'] : null;
    }
}
