import { Time } from "@angular/common";

export enum ProductionStatus {
    new = 1,
    sent = 2,
    received = 3,
    entered = 6,
    to_email = 8,
    to_mail = 9,
    online_awaiting = 10,
    in_progress = 11,
    submitted = 12,
    approved = 13,
    issues_found = 14,
    change_requested = 15
}

export interface Recipient {
    person_id?: number,
    email?: string,
    company_name?: string,
    first_name?: string,
    last_name?: string
}

export interface GPU {
    gpu_id?: number,
    gpu_name?: string,
    gpu_number?: number,
    recipient?: Recipient,
    report_recipient_id?: number
}

export interface GPUYearProductionStatus {
    gpu_id?: number,
    list_production_status?: string,
    list_production_status_id?: number,
    production_id?: number,
    production_data?: any,
    year?: string,
    gpu?: GPU,
    hidden?: boolean
}

export interface MeterInspection {
    visible_to_producer?: boolean;
    acre_feet?: number,
    cropped_acres?: number,
    crops_grown?: string,
    gpm?: number,
    ignore_reading?: boolean,
    in_compliance?: boolean,
    initial_meter_reading?: number,
    inspected_by?: string,
    inspection_date?: Date,
    inspection_notes?: string,
    inspection_time?: Time,
    list_meter_inspection_type_id?: number,
    meter_id?: number,
    meter_reading?: number,
    reporting_year?: number,
    sub_total?: number,
    meter_type_description?: string,
    list_meter_type_id?: number,
    multiplier?: number
}

export interface MeterChangeRequest {
    meter_change_request_id?: number;
    date_requested?: Date,
    change_type_id: number,
    old_meter_number?: number,
    old_meter_reading?: number,
    reason_id?: number,
    new_meter_type_id?: number,
    new_meter_brand?: string,
    new_meter_serial?: string,
    new_meter_reading?: number,
    new_meter_location_id?: number,
    new_meter_location_section?: string,
    meter_change_status_id?: number,
    reason_other?: string,
    production_id: number
}

export interface ProductionReportDownloadRequest {
    production_id?: number
    template_path: string
    template_send: 'downloadnow' | 'emailme'
    pid_values_array?: [string|number]
    record?: any
}
