<!--<div class="modal fade" id="meterChangeModal" tabindex="-1" role="dialog" aria-labelledby="helpModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Meter Change Request</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                meter-change-request


                <button type="button" class="btn btn-primary" (click)="save() ">Save</button>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>-->
<div class="meterChangeRequestFormDiv" role="document" data-target="#modal" *ngIf="modalVisible">

    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Meter Change Request</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-center">

        <div *ngFor="let ga of alertMessages" class="mx-4">
            <div class="alert alert-{{ ga.type }} alert-dismissible fade show" role="alert">
                <button type="button" class="close float-right" data-bs-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <pre>{{ ga.text }}</pre>
            </div>
        </div>

        <form (submit)="save()" class="modal-content pt-3" ngNativeValidate>
            <div class="form-group row mb-1 P-2">
                <label class="col-sm-4 col-form-label" for="change_type_id">Change Type</label>
                <div class="col-sm-8">
                    <select class="form-select form-control" id="change_type_id" [(ngModel)]="mcRequest.change_type_id" name="change_type_id" required>
                        <option *ngFor="let s of list_meter_change_type"
                                class=""
                                [ngValue]="s.id"
                                title="{{ s.label }}">
                            {{ s.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mb-1">
                <label class="col-sm-4 col-form-label" for="reason_id">Change Reason</label>
                <div class="col-sm-8">
                    <select class="form-select form-control" id="reason_id" [(ngModel)]="mcRequest.reason_id" name="reason_id" required>
                        <option *ngFor="let s of list_meter_change_reason"
                                class=""
                                [ngValue]="s.id"
                                title="{{ s.label }}">
                            {{ s.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="this.mcRequest.reason_id == 4">
                <label class="col-sm-4 col-form-label" for="reason_other">Reason Other</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="reason_other" [(ngModel)]="mcRequest.reason_other" name="reason_other" placeholder="reason_other" required>
                </div>
            </div>


            <div class="form-group row mb-1" *ngIf="[2,3].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="old_meter_number">Old Meter ID</label>
                <div class="col-sm-8">
                    <select class="form-select form-control" id="old_meter_number" [(ngModel)]="mcRequest.old_meter_number" name="old_meter_number" required>
                        <option *ngFor="let s of meter_number_array"
                                class=""
                                [ngValue]="s"
                                title="{{ s }}">
                            {{ s }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[2,3].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="old_meter_reading">Old Meter Reading</label>
                <div class="col-sm-8">
                    <input type="number" class="form-control" id="old_meter_reading" [(ngModel)]="mcRequest.old_meter_reading" name="old_meter_reading" step="0.000001" min="0" required>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[1,2].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="new_meter_type_id">New Meter Type</label>
                <div class="col-sm-8">
                    <select class="form-select form-control" id="new_meter_type_id" [(ngModel)]="mcRequest.new_meter_type_id" name="new_meter_type_id" required>
                        <option *ngFor="let s of list_meter_type"
                                class=""
                                [ngValue]="s.id"
                                title="{{ s.label }}">
                            {{ s.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[1,2].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="new_meter_brand">New Meter Brand</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="new_meter_brand" [(ngModel)]="mcRequest.new_meter_brand" name="new_meter_brand" required>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[1,2].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="new_meter_serial">New Meter Serial</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="new_meter_serial" [(ngModel)]="mcRequest.new_meter_serial" name="new_meter_brand" required>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[1,2].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="new_meter_reading">New Meter Reading</label>
                <div class="col-sm-8">
                    <input type="number" class="form-control" id="new_meter_reading" [(ngModel)]="mcRequest.new_meter_reading" name="new_meter_reading" step="0.000001" min="0" required>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[1,2].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="new_meter_location_id">New Meter Location</label>
                <div class="col-sm-8">
                    <select class="form-select form-control" id="new_meter_location_id" [(ngModel)]="mcRequest.new_meter_location_id" name="new_meter_location_id" required>
                        <option *ngFor="let s of list_meter_location"
                                class=""
                                [ngValue]="s.id"
                                title="{{ s.label }}">
                            {{ s.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mb-1" *ngIf="[1,2].includes(this.mcRequest.change_type_id)">
                <label class="col-sm-4 col-form-label" for="new_meter_location_section">New Meter Well Information</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="new_meter_location_section" [(ngModel)]="mcRequest.new_meter_location_section" name="new_meter_location_section" required>
                </div>
            </div>

            <button type="submit" class="btn btn-primary float-end m-4" data-dismiss="modal">Submit</button>
        </form>
    </div>
</div>