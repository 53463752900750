<!--<div class="modal fade" id="meterChangeModal" tabindex="-1" role="dialog" aria-labelledby="helpModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Meter Change Request</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                meter-change-request


                <button type="button" class="btn btn-primary" (click)="save() ">Save</button>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>-->
<div class="meterChangeRequestFormDiv" role="document" data-target="#modal" *ngIf="modalVisible">

    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Download Production Report</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body text-center">

        <div *ngFor="let ga of alertMessages" class="mx-4">
            <div class="alert alert-{{ ga.type }} alert-dismissible fade show" role="alert">
                <button type="button" class="close float-right" data-bs-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <pre>{{ ga.text }}</pre>
            </div>
        </div>

        <form (submit)="save()" class="modal-content pt-3" ngNativeValidate>
            <div class="form-group row mb-1 P-2">
                <label class="col-sm-4 col-form-label" for="template_path">Template</label>
                <div class="col-sm-8">
                    <select class="form-select form-control" id="template_path" [(ngModel)]="mcRequest.template_path" name="template_path" required>
                        <option *ngFor="let s of list_meter_change_type"
                                class=""
                                [ngValue]="s.path"
                                title="{{ s.label }}">
                            {{ s.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group row mb-1">
                <!-- TODO show template description -->
            </div>

            <button type="submit" class="btn btn-primary float-end m-4" data-dismiss="modal" [disabled]="isReportDownloading">{{isReportDownloading ? 'Generating report...' : 'Submit'}}</button>
        </form>
    </div>
</div>