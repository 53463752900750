<div class="base-page base-page-background-img">
    <div class="center">
        <div class="container-fluid"></div>
        <div class="row w-100">
            <div class="col mx-auto my-auto">
                <div class="login-rectangle p-3 mb-5 bg-white rounded">
                    <!-- LOGO -->
                    <div class="text-center">
                        <img class="mt-5 mb-3" src="../assets/npgcd-in-house-logo.jpg" alt="North Plains Logo">
                    </div>
                    <div class="text-center login-header m-5">Sign In</div>
                    <div class="text-center login-text-description m-5">Enter your Mobile Phone Number and Access Code</div>
                    <div class="row">
                        <div class="col">
                            <ng-container *ngIf="alertMessages">
                                <div *ngFor="let a of alertMessages">
                                    <div class="alert alert-{{ a.type }} alert-dismissible fade show" role="alert">
                                        <button type="button" class="close float-right" data-bs-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <pre>{{ a.text }}</pre>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <form>
                                    <div class="text-center" *ngIf="visEnterPhoneNumber">
                                        <input class="m-2" 
                                               type="text" 
                                               id="inPhoneNumberVisible" 
                                               name="inPhoneNumberVisible" 
                                               [(ngModel)]="inPhoneNumberVisible" 
                                               placeholder="Phone Number" 
                                               (change)="userPhoneNumberInputChange()"
                                               (keyup)="userPhoneNumberInputChange()">
                                        <div class="m-2 text-center">
                                            <button type="submit" class="btn login-btn-lt-grn" (click)="sendCode()">Send Access Code</button>
                                        </div>
                                    </div>
                                    <div class="text-center" *ngIf="visEnterPassCode">
                                        <input class="m-2" 
                                               type="text" 
                                               id="inPasscode" 
                                               name="inPasscode" 
                                               [(ngModel)]="inPasscode" 
                                               placeholder="Access Code">
                                        <div class="m-2 text-center">
                                            <button type="submit" class="btn login-btn-lt-grn" (click)="userLogin()">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>