import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeterChangeRequest } from '../interfaces/portal-api.interface';
import { PortalApiService } from '../services/portal-api/portal-api.service';
import { ModalComponent } from 'ngb-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-meter-change-request',
  templateUrl: './meter-change-request.component.html',
  styleUrls: ['./meter-change-request.component.scss']
})
export class MeterChangeRequestComponent {

    unsubscribe: Subject<any> = new Subject();

    list_meter_change_type: any;
    list_meter_type: any;
    list_meter_change_status: any;
    list_meter_location: any;
    list_meter_change_reason: any;
    list_meter_status: any;

    public myContent: any;
    public production_id: number;
    public meter_number_array: Array<string>;

    @ViewChild(ModalComponent) ModalComponent: any;

    mcRequest: MeterChangeRequest;
    alertMessages: Array<any>;
    modalVisible = false;

    constructor(
        private portalApiService: PortalApiService,
        public modalRef: BsModalRef
    ) {
        this.mcRequest = {production_id: -1, 'change_type_id': 0 };
        this.production_id = -1;
        this.meter_number_array = [];
        this.alertMessages = [];
        this.modalVisible = true;

        this.portalApiService.list_meter_change_reason
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.list_meter_change_reason = data;
                }
            });
        this.portalApiService.list_meter_change_status
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.list_meter_change_status = data;
                }
            });
        this.portalApiService.list_meter_change_type
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.list_meter_change_type = data;
                }
            });
        this.portalApiService.list_meter_location
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    // remove the None option
                    this.list_meter_location = data.filter(data => data.val !== 'None');
                }
            });
        this.portalApiService.list_meter_status
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.list_meter_status = data;
                }
            });
        this.portalApiService.list_meter_type
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data != null) {
                    this.list_meter_type = data;
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    save() {
        this.mcRequest.date_requested = new Date();
        this.mcRequest.production_id = this.production_id;

        let fields_old = ['old_meter_number', 'old_meter_reading']
        let fields_new = ['new_meter_type_id', 'new_meter_brand', 'new_meter_serial', 'new_meter_reading', 'new_meter_location_id', 'new_meter_location_section']

        if (this.mcRequest.change_type_id == 1) {
            let mcRLocal = this.mcRequest;
            fields_old.forEach((element) => {
                mcRLocal[element] = null 
            });
        }
        if (this.mcRequest.change_type_id == 3) {
            let mcRLocal = this.mcRequest;
            fields_new.forEach((element) => {
                mcRLocal[element] = null
            });
        }

        let promise = this.portalApiService.submitMeterChangeRequest(this.mcRequest);
        promise.then((value) => {
            console.log(value);
        }).catch((response) => {
            if (response.error) {
                this.alertMessages.push({ type: 'danger', text: response.error.message });
            }
        }).finally(() => {
            this.modalRef.hide();
            this.portalApiService.getGPUProductionForID(String(this.mcRequest.production_id));
        });
    }
}
