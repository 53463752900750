import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { API_URL, DEFAULT_PRODUCTION_YEAR, LIST_METER_TYPE_ID_INCLUDE_GPM, LIST_METER_TYPE_ID_ZERO_INITIAL_METER_READING, POSTGREST_URL } from '../../env';
import {
    GPUYearProductionStatus,
    MeterInspection,
    ProductionReportDownloadRequest
} from '../../interfaces/portal-api.interface';

@Injectable({
  providedIn: 'root'
})

export class PortalApiService {
    unsubscribe: Subject<any> = new Subject();
    private gpusListData = new BehaviorSubject<Array<GPUYearProductionStatus>>([]);
    public gpusListDataObservable = this.gpusListData.asObservable();
    public meterTypeIDsIncludeGPM: number[];
    public meterTypeIDsIncludeZeroInitialReading: number[];

    private userNameData = new BehaviorSubject<any>('');
    public userNameDataObservable = this.userNameData.asObservable();

    public userData = new BehaviorSubject<any>(null);
    public userDataObservable = this.userData.asObservable();

    public recordData = new BehaviorSubject<any>(null);
    public record = this.recordData.asObservable();
    public template_listData = new BehaviorSubject<any>(null);
    public template_list = this.template_listData.asObservable();
    public list_meter_change_typeData = new BehaviorSubject<any>(null);
    public list_meter_change_type = this.list_meter_change_typeData.asObservable();
    public list_meter_change_statusData = new BehaviorSubject<any>(null);
    public list_meter_change_status = this.list_meter_change_statusData.asObservable();
    public list_meter_locationData = new BehaviorSubject<any>(null);
    public list_meter_location = this.list_meter_locationData.asObservable();
    public list_meter_typeData = new BehaviorSubject<any>(null);
    public list_meter_type = this.list_meter_typeData.asObservable();
    public list_meter_change_reasonData = new BehaviorSubject<any>(null);
    public list_meter_change_reason = this.list_meter_change_reasonData.asObservable();
    public list_meter_statusData = new BehaviorSubject<any>(null);
    public list_meter_status = this.list_meter_statusData.asObservable();
    

    private skeletonData: GPUYearProductionStatus[];

    constructor(private http: HttpClient, private router: Router) {
        this.meterTypeIDsIncludeGPM = LIST_METER_TYPE_ID_INCLUDE_GPM;
        this.meterTypeIDsIncludeZeroInitialReading = LIST_METER_TYPE_ID_ZERO_INITIAL_METER_READING;

        // For a temporary skeleton item encase it takes a moment for the Production records to load, gets replaced as soon as the call returns with the users actual production records.
        this.skeletonData = [];
        let ndata0: GPUYearProductionStatus = { production_id: -1, hidden: false, year: String(DEFAULT_PRODUCTION_YEAR), list_production_status: '...loading', list_production_status_id: -1 };
        this.skeletonData.push(ndata0);
        
        this.gpusListData.next(this.skeletonData);







        this.getTableData('app_config_edit?select=template_list&resource_template=eq.production_template_tabs').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_change_type = value.body;
                    this.template_listData.next(value.body[0].template_list
                        .map((t) => {
                            const parts = t.split(':');
                            if (parts.length < 2) {
                                console.warn('malformed template string: %s', t);
                                return null;
                            }

                            return {path: parts[0], label: parts[1], full: t};
                        }).filter(t => t) // remove in valid templates
                    );
                }
            }
        });

        this.getTableData('list_meter_change_type').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_change_type = value.body;
                    this.list_meter_change_typeData.next(value.body);
                }
            }
        });
        this.getTableData('list_meter_change_status').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_change_status = value.body;
                    this.list_meter_change_statusData.next(value.body);
                }
            }
        });
        this.getTableData('list_meter_location').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_location = value.body;
                    this.list_meter_locationData.next(value.body);
                }
            }
        });
        this.getTableData('list_meter_type').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_type = value.body;
                    this.list_meter_typeData.next(value.body);
                }
            }
        });
        this.getTableData('list_meter_change_reason').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_change_reason = value.body;
                    this.list_meter_change_reasonData.next(value.body);
                }
            }
        });
        this.getTableData('list_meter_status').then((value: any) => {
            console.log(value);
            if (value.status == 200) {
                if (value.body) {
                    //this.list_meter_status = value.body;
                    this.list_meter_statusData.next(value.body);
                }
            }
        });
    }

    test(): Observable<any> {
        return this.http
        .get(`${API_URL}/`)
        .pipe(takeUntil(this.unsubscribe))
    }

    getUserPersonInfo(inPhone: string) {
        var formData = new FormData();
        formData.append("phone", inPhone);
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/getuserinfo`, formData, { observe: 'response' })
                .toPromise()
                .then((response: any) => resolve(response), (error: any) => resolve(error));
        });
        promise.then((value: any) => {
            if (value.status == 200) {
                if (value.body) {
                    const userNameConcat = String(value.body.user_info.first_name) + ' ' + String(value.body.user_info.last_name);
                    this.userNameData.next(userNameConcat);
                    this.userData.next(value.body.user_info);
                }
            }
        })
    };

    getUserGPUs() {
        let formData = new FormData();
        formData.append("function_name", 'portal_user_gpus');
        let function_param_name_value_pairs = [];
        function_param_name_value_pairs.push({'pyear': DEFAULT_PRODUCTION_YEAR });
        formData.append("function_param_name_value_pairs", JSON.stringify(function_param_name_value_pairs));
        this.http.post<any>(`${API_URL}/api/postgrest`, formData, { observe: 'response' }).subscribe(
            (response) => {
                if (response.status === 200) {
                    if (response.body.data) {
                        this.gpusListData.next(response.body.data);
                    }
                }
            },
            (error) => {
                console.log(error.error);
                this.router.navigate(['app/login']);
            });
    }

    getGPUProductionForID(production_id: string) {
        let formData = new FormData();
        formData.append("function_name", 'portal_gpu_production');
        let function_param_name_value_pairs = [];

        function_param_name_value_pairs.push({'pproductionid': production_id });

        formData.append("function_param_name_value_pairs", JSON.stringify(function_param_name_value_pairs));
        this.http.post<any>(`${API_URL}/api/postgrest`, formData, { observe: 'response' }).subscribe(response => {
            if (response.status === 200) {
                if (response.body.data) {
                    this.updateGPUsListDataWithThisProduction(production_id, response.body.data);
                }
            }
        });
    }

    updateGPUsListDataWithThisProduction(production_id:any, data: any) {
        let currentGPUSList = this.gpusListData.getValue();
        for (let i = 0; i < currentGPUSList.length; i++) {
            let gpu = currentGPUSList[i];
            if (gpu['production_id'] == data['production_id']) {
                // update the production status encases it has changed (happens server side)
                gpu.list_production_status_id = data.list_production_status_id;
                gpu.list_production_status = data.list_production_status;
                if (data.meter) {
                    for (let m = 0; m < data.meter.meters.length; m++) {
                        let dMeter = data.meter.meters[m];
                        if (dMeter.inspections == null) {
                            let emptyNewMeterInspection: MeterInspection = {};
                            dMeter.inspections = [];
                            emptyNewMeterInspection.meter_id = dMeter.meter_id;
                            emptyNewMeterInspection.reporting_year = DEFAULT_PRODUCTION_YEAR;
                            emptyNewMeterInspection.initial_meter_reading = 0;
                            emptyNewMeterInspection.visible_to_producer = true;
                            emptyNewMeterInspection.ignore_reading = false;
                            emptyNewMeterInspection.gpm = 1;
                            dMeter.inspections.push(emptyNewMeterInspection);
                        }
                        if (dMeter.inspections.length != undefined) {
                            dMeter.inspections = dMeter.inspections.sort(this.compare_year_then_reading);
                            const lastInspection = dMeter.inspections[dMeter.inspections.length - 1];
                            const last_inspection_less_than_year = lastInspection.reporting_year < DEFAULT_PRODUCTION_YEAR;
                            if (last_inspection_less_than_year || (lastInspection.visible_to_producer != true)) {
                                let newMeterInspection: MeterInspection = {};
                                newMeterInspection.reporting_year = DEFAULT_PRODUCTION_YEAR;
                                newMeterInspection.meter_id = lastInspection.meter_id;
                                newMeterInspection.visible_to_producer = true;
                                newMeterInspection.ignore_reading = false;
                                // if we are adding a new blank for
                                if (this.meterTypeIDsIncludeZeroInitialReading.includes(dMeter.list_meter_type_id)) {
                                    newMeterInspection.initial_meter_reading = 0;
                                } else {
                                    // get previous years inspection for this meter
                                    newMeterInspection.initial_meter_reading = lastInspection.meter_reading; // get the value from the previous year
                                }

                                if (this.meterTypeIDsIncludeGPM.includes(dMeter.list_meter_type_id)) {
                                    newMeterInspection.gpm = lastInspection.gpm; // get the value from the previous year
                                } else {
                                    newMeterInspection.gpm = 1;
                                }
                                dMeter.inspections.push(newMeterInspection);
                            }

                            // for any visible meter_inspection rows fetch the files
                            for (let d = 0; d < dMeter.inspections.length; d++) {
                                let thisInspection = dMeter.inspections[d];
                                let matchingYear = (thisInspection.reporting_year == DEFAULT_PRODUCTION_YEAR);
                                if (matchingYear && (thisInspection.visible_to_producer == true)) {
                                    if (thisInspection.meter_inspection_id) {
                                    thisInspection.fileFormForFileData = new FormGroup({
                                        'name': new FormControl(null),
                                        'file': new FormControl(null)
                                    });
                                    this.getFilesForMeter(production_id, dMeter.meter_id, thisInspection.meter_inspection_id)

                                    }
                                }
                            }
                        }
                    }

                }
                gpu['production_data'] = data;
            }
        }
        this.gpusListData.next(currentGPUSList);
    }

    sortAGPUMeters(gpuData: any) {
        let data_sorted = gpuData;
        try {
            let metersList = gpuData.meter;
            for (let i = 0; i < metersList.length; i++) {
                let meterInspectionsList = metersList[i];
                meterInspectionsList = meterInspectionsList.sort(this.compare_year_then_reading);
            }
        } catch (error) {
            console.error(error);
            return data_sorted;
        }
    }

    compare_year_then_reading(a: any, b: any) {
        if (a.reporting_year < b.reporting_year) {
            return -1;
        }
        if (a.reporting_year > b.reporting_year) {
            return 1;
        }
        if (a.meter_inspection_id < b.meter_inspection_id) {
            return -1;
        }
        if (a.meter_inspection_id > b.meter_inspection_id) {
            return 1;
        }
        return 0;
    }

    updateGPUsListDataForStatus(statusID: number) {
        let currentGPUSList = this.gpusListData.getValue();
        for (let i = 0; i < currentGPUSList.length; i++) {
            let gpu = currentGPUSList[i];
            if ((statusID == 0) || (String(gpu['list_production_status_id']) == String(statusID))) {
                gpu['hidden'] = false;
            } else {
                gpu['hidden'] = true;
            }
        }
        this.gpusListData.next(currentGPUSList);
    }

    checkMeterSerialNumbers(prodGPU: any, searchString: string) {
        try {
            if (!prodGPU.production_data) {
                return false;
            }
            // prodGPU.production_data.meter.meters[0].serial_number // string
            for (let i = 0; i < prodGPU.production_data.meter.meters.length; i++) {
                let meter = prodGPU.production_data.meter.meters[i];
                if (String(meter.serial_number).toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error('failed to check meter serial numbers');
            console.error(error);
            return false;
        }
    }

    updateGPUsListDataForSearchString(searchString: string) {
        let currentGPUSList = this.gpusListData.getValue();
        for (let i = 0; i < currentGPUSList.length; i++) {
            let prodGPU = currentGPUSList[i];
            let gpu = prodGPU['gpu'];
            if (gpu) {
                if (String(gpu['gpu_name']).toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    prodGPU['hidden'] = false;
                } else if (String(gpu['gpu_number']).toLowerCase().indexOf(searchString.toLowerCase()) != -1) {
                    prodGPU['hidden'] = false;
                // This is slightly redundent since there is  the search meter serial numbers dedicated search box now.
                } else if (this.checkMeterSerialNumbers(prodGPU, searchString)) {
                    prodGPU['hidden'] = false;
                } else {
                    prodGPU['hidden'] = true;
                }
            }
        }
        this.gpusListData.next(currentGPUSList);
    }

    getTableData(tableName: string) {
        let promise = new Promise<any>((resolve, reject) => {
            this.http.get<any>(`${POSTGREST_URL}/${tableName}`, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally();
        });
        return promise;
    }

    saveMeterInspections(dataObj: any) {
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/savemeterinspections`, dataObj, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally();
        });
        return promise;
    }

    submitProduction(dataObj: any) {
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/submitproduction`, dataObj, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally();
        });
        return promise;
    }

    uploadMeterFile(formData: any) {
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/uploadmeterfile`, formData, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(response => resolve(response));
        });
        return promise;
    }

    deleteMeterFile(formData: any) {
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/deletefile`, formData, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(response => resolve(response));
        });
        return promise;
    }

    getFilesForMeter(production_id: any, meter_id: number, meter_inspection_id: number) {
        let promise = new Promise<any>((resolve, reject) => {
            this.http.get<any>(`${API_URL}/api/getmeterfiles?meter_id=${String(meter_id)}&meter_inspection_id=${String(meter_inspection_id)}`, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(response => resolve(response));
        });
        promise.then((value) => {
            let currentGPUSList = this.gpusListData.getValue();
            for (let gi = 0; gi < currentGPUSList.length; gi++) {
                let aGPU = currentGPUSList[gi];
                if (aGPU.production_id == production_id) {
                    let production_data = aGPU['production_data'];
                    for (let m = 0; m < production_data.meter.meters.length; m++) {
                        let dMeter = production_data.meter.meters[m];
                        if (meter_id == dMeter.meter_id) {
                            for (let mi = 0; mi < dMeter.inspections.length; mi++) {
                                let dMeterInspection = dMeter.inspections[mi];
                                if (meter_inspection_id == dMeterInspection.meter_inspection_id) {
                                    dMeterInspection.filesUploadedList = value.body.files;
                                    continue
                                }
                            }
                        }
                    }
                }
            }
            this.gpusListData.next(currentGPUSList);
        }).catch((response) => {
            console.log('Error', response);
            if (response.error) {
                let currentGPUSList = this.gpusListData.getValue();
                // TODO - improve this by creating a helper function, this nested set of for loops is identical to the one used in the .then promise
                for (let gi = 0; gi < currentGPUSList.length; gi++) {
                    let aGPU = currentGPUSList[gi];
                    if (aGPU.production_id == production_id) {
                        let production_data = aGPU['production_data'];
                        for (let m = 0; m < production_data.meter.meters.length; m++) {
                            let dMeter = production_data.meter.meters[m];
                            if (meter_id == dMeter.meter_id) {
                                if (!dMeter.alertMessages) {
                                    dMeter.alertMessages = [];
                                }
                                dMeter.alertMessages.push({ type: 'danger', text: String(response.error.message) + ' Please contact district for assistance.' });
                                continue
                            }
                        }
                    }
                }
            }
        });
        return promise;
    }

    downloadFile(formData: any): Observable<any> {
        return this.http.post(`${API_URL}/api/downloadfile`, formData, { responseType: 'blob' } );
    }

    searchBasedOnMeterSerial(inStr: string) {
        if (inStr == '') {
            this.updateGPUsListDataResetHidden();
            return;
        }

        let formData = new FormData();
        formData.append("function_name",'portal_search_meter');
        let function_param_name_value_pairs = [];
        function_param_name_value_pairs.push({'pserial_number': inStr });
        function_param_name_value_pairs.push({'pyear': DEFAULT_PRODUCTION_YEAR });
        formData.append("function_param_name_value_pairs", JSON.stringify(function_param_name_value_pairs));
        this.http.post<any>(`${API_URL}/api/postgrest`, formData, { observe: 'response' }).subscribe(response => {
            if (response.status === 200) {
                if (response.body.success == true) {
                    let res = response.body.data;
                    if (res == null) {
                        res = [];
                    }
                    this.updateGPUsListDataForTheseMeters(res);
                }
            }
        });
    }


    updateGPUsListDataForTheseMeters(meterArray: Array<any>) {
        let productionIDArray = [];
        for (let i = 0; i < meterArray.length; i++) {
            let item = meterArray[i];
            productionIDArray.push(item['production_id']);
        }

        let currentGPUSList = this.gpusListData.getValue();
        for (let i = 0; i < currentGPUSList.length; i++) {
            let prodGPU = currentGPUSList[i];
            if (productionIDArray.includes(prodGPU['production_id'])) {
                prodGPU['hidden'] = false;
            } else {
                prodGPU['hidden'] = true;
            }
        }
        this.gpusListData.next(currentGPUSList);
    }

    submitMeterChangeRequest(meterChangeRequestObj: any) {
        let formData = new FormData();
        formData.append("table_name", 'meter_change_request');
        formData.append("function_param_name_value_pairs", JSON.stringify(meterChangeRequestObj));
        let promise = new Promise<any>((resolve, reject) => {
            this.http.post<any>(`${API_URL}/api/post-to-table`, formData, { observe: 'response' })
                .toPromise()
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally();
        });
        return promise;
    }

    submitProductionReportDownloadRequest(mcRequest: ProductionReportDownloadRequest) {
        let formData = new FormData();
        formData.append('template_send', 'downloadnow');
        formData.append('resource', 'production');
        formData.append('resource_query', 'rpc/get_production_record((pgpu:<<gpu_id>>))((pyear:<<year>>))')
        formData.append('app_config_edit_id', '6'); // resource = production / resource_label = Production Report
        formData.append('template_path', mcRequest.template_path);
        formData.append('record', JSON.stringify(mcRequest.record));
        // formData.append('use_resource_query_column', null);
        formData.append('pid_values_array', JSON.stringify([mcRequest.production_id]));
        return this.http.post<any>(`${API_URL}/api/generateFormExternal`, formData )
                .toPromise();
    }

    getRecord(gpuObj, year) {
        const body = new URLSearchParams();
        body.set('pgpu', gpuObj.gpu_id);
        body.set('pyear', year);
        return this.http.post<any>(`${POSTGREST_URL}/rpc/get_production_record`, body.toString(), {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded;'})}).pipe();
    }

    updateGPUsListDataResetHidden() {
        let currentGPUSList = this.gpusListData.getValue();
        for (let i = 0; i < currentGPUSList.length; i++) {
            let prodGPU = currentGPUSList[i];
            prodGPU['hidden'] = false;
        }
        this.gpusListData.next(currentGPUSList);
    }
}