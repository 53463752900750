<div class="navbar-gpu-page">
    <div class="w-100">
        <div class="row">
            <div class="col mx-auto my-auto">
                <a class="mr-5 navbar-brand" href="#">
                    <img class="float-left d-inline-block align-top" src="../assets/logo_h85.jpg" alt="North Plains Logo">
                </a>
                <div class="ml-1 gpu-navbar-header-text">{{searchYear}} Production Report</div>
                <div class="navbar-user-rectangle float-end ms-auto">
                    <mat-icon class="material-icons">person</mat-icon>{{userName}}
                </div>
                <div class="float-end ms-auto">
                    <mat-icon class="material-icons nav-bar-icon" data-bs-toggle="modal" data-bs-target="#helpModal">help_outline</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="base-page">
    <div class="container-fluid m-w-1200">
        <div class="row">
            <div class="col mx-auto my-auto gpu-search-filter-area pt-4 pb-4">
                <div class="row mt-2">
                    <div class="col-2">
                        <span class="mr-1">GPU <mat-slide-toggle [(ngModel)]="viewSearchMeterSerialNumber" (change)="toggleSearch();" ></mat-slide-toggle> Meter</span>
                    </div>
                    <div class="col-4 search-meter-serial-number-input-div">
                        <ng-container *ngIf="!viewSearchMeterSerialNumber">
                            <input type="text"
                                   class="form-control"
                                   id="inSearch"
                                   placeholder="Search GPU"
                                   (input)="onSearchChange($event)">
                        </ng-container>
                        <ng-container *ngIf="viewSearchMeterSerialNumber">
                            <input type="text"
                                   class="form-control float-start"
                                   id="inSearchMeter"
                                   placeholder="Enter Meter Serial Number"
                                   [(ngModel)]="searchMeterSerialNumberValue"><i (click)="searchMeterSerialNumber($event)" class="material-icons">search</i>
                        </ng-container>
                    </div>
                    <div class="col-6 ms-auto mr-1">
                        <div class="sel-filter-outer float-right">
                            <div class="form-group " id="SelectFilterDropdown">
                                <label for="inputFilterStatusType" class="gpu-title-name">Filter</label>
                                <select class="custom-select m-2"
                                        id="inputFilterStatusType"
                                        (change)="onStatusFilterChange($event)"
                                        [(ngModel)]="gpuStatusFilterSelectedText">
                                    <ng-container *ngFor="let s of gpuStatusList">
                                        <option class="mat-menu-item-gpu-status"
                                                value="{{ s.id }}"
                                                title="{{ s.text }}">
                                            {{ s.text }}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col mx-auto my-auto">
                    <div class=" mb-5 rounded">
                        <div class="gpu-section">
                            <div class="accordion" id="gpuAccordionPanels">

                                <ng-container *ngFor="let gpuProd of gpusList;let indexOfelement=index;">
                                    <div class="accordion-item" *ngIf="!gpuProd.hidden" [ngStyle]="{'z-index': 400 - indexOfelement}" is-disabled="true" >
                                        <h2 class="accordion-header" 
                                            [attr.id]="'panelsStayOpen-headingOne' + gpuProd.production_id" 
                                            (click)="onProductionHeaderClicked($event,gpuProd.production_id)">
                                            <button class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    [disabled]="gpuProd.list_production_status_id == -1"
                                                    [attr.data-bs-target]="'#panelsStayOpen-collapseOne' + gpuProd.production_id"
                                                    aria-expanded="false"
                                                    [attr.aria-controls]="'panelsStayOpen-collapseOne' + gpuProd.production_id">
                                                <div class="w-100" *ngIf="gpuProd.list_production_status_id == -1">
                                                    <ngx-skeleton-loader count="1"
                                                                         appearance="line"
                                                                         animation="progress-dark"></ngx-skeleton-loader>
                                                </div>
                                                <div class="two-text-high">
                                                    <div class="gpu-header-main-text ml-1 mb-0 float-left">{{gpuProd?.gpu?.gpu_name}}</div>
                                                    <div class="gpu-header-sub-text">{{gpuProd?.gpu?.gpu_number}}</div>
                                                </div>

                                                <div class="gpu-status-button">
                                                    <div class="badge-npp"
                                                         [ngClass]="{
                                                         'npp-status-awaiting':gpuProd.list_production_status_id == this.ProductionStatus.online_awaiting,
                                                         'npp-status-in-progress': gpuProd.list_production_status_id == this.ProductionStatus.in_progress,
                                                         'npp-status-submitted':gpuProd.list_production_status_id == this.ProductionStatus.submitted,
                                                         'npp-status-approved':gpuProd.list_production_status_id == this.ProductionStatus.approved,
                                                         'npp-status-issues-found':gpuProd.list_production_status_id == this.ProductionStatus.issues_found,
                                                         'npp-status-change-requested':gpuProd.list_production_status_id == this.ProductionStatus.change_requested
                                                         }">
                                                        {{gpuProd.list_production_status}}
                                                    </div>
                                                </div>
                                            </button>

                                            <ng-container *ngIf="gpuProd.alertMessages">
                                                <div *ngFor="let ga of gpuProd.alertMessages" class="mx-4">
                                                    <div class="alert alert-{{ ga.type }} alert-dismissible fade show" role="alert">
                                                        <button type="button" class="close float-right" data-bs-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                        <pre>{{ ga.text }}</pre>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </h2>
                                        <div [attr.id]="'panelsStayOpen-collapseOne' + gpuProd.production_id" 
                                             class="accordion-collapse collapse" 
                                             [attr.aria-labelledby]="'panelsStayOpen-headingOne' + gpuProd.production_id">
                                            <div class="accordion-body">


                                                <div class="mb-3 min-h-30 text-end">
                                                    <button type="button"
                                                            class="btn btn-secondary mx-2"
                                                            [ngClass]="{'disabled': gpuProd.production_data == undefined}"
                                                            (click)="openDownloadProductionReportModal($event,gpuProd);">Download Production Report</button>
                                                    <button type="button"
                                                            class="btn btn-secondary"
                                                            [ngClass]="{'disabled': gpuProd.production_data == undefined}"
                                                            (click)="openMeterChangeRequestModal($event,gpuProd);">Meter Change Request</button>
                                                </div>

                                                <div class="gpu-attrs-table-outer bg-light  mb-4" *ngIf="gpuProd?.production_data">
                                                    <table class="table table-borderless gpu-attrs-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Total Acres</th>
                                                                <th scope="col">Annual Allowable</th>
                                                                <th scope="col">Available Reserve</th>
                                                                <th scope="col">Production Limit</th>
                                                                <th scope="col">Total {{gpuProd?.year}} Production (Actual Usage)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{{gpuProd?.production_data?.gpu?.ttl_prop_prod_ac}}</td>
                                                                <td>{{gpuProd?.production_data?.base_allocated_usage}}</td>
                                                                <td>{{gpuProd?.production_data?.reserve_allocated_usage}}</td>
                                                                <td>{{gpuProd?.production_data?.total_allocated_usage}}</td>
                                                                <td>
                                                                    <input disabled
                                                                           type="text"
                                                                           class=""
                                                                           value="{{gpuProd?.production_data?.actual_usage}}" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="gpu-attrs-table-outer bg-light  mb-4" *ngIf="!gpuProd?.production_data">
                                                    <div class="gpu-inspection-rectangle p-3">
                                                        <ngx-skeleton-loader count="1"
                                                                             appearance="line"
                                                                             animation="progress-dark"></ngx-skeleton-loader>
                                                    </div>
                                                </div>

                                                <div class="bg-light border-bottom" *ngIf="!gpuProd?.production_data">
                                                    <div class="gpu-inspection-rectangle p-3">
                                                        <ngx-skeleton-loader count="1"
                                                                             appearance="line"
                                                                             animation="progress-dark"></ngx-skeleton-loader>
                                                    </div>
                                                </div>

                                                <div class="bg-light border-bottom"
                                                     *ngFor="let meter of gpuProd?.production_data?.meter?.meters; let indexOfMeterElement=index;">
                                                    <div class="gpu-inspection-rectangle p-3">
                                                        <table class="table table-borderless gpu-inspections-table-top">
                                                            <thead>
                                                                <tr>
                                                                    <th colspan="4">
                                                                        <ng-container *ngIf="meter.alertMessages">
                                                                            <div *ngFor="let ma of meter.alertMessages">
                                                                                <div class="alert alert-{{ ma.type }} alert-dismissible fade show" role="alert">
                                                                                    <button type="button" class="close float-right" data-bs-dismiss="alert" aria-label="Close">
                                                                                        <span aria-hidden="true">&times;</span>
                                                                                    </button>
                                                                                    <pre>{{ ma.text }}</pre>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="col">Serial #</th>
                                                                    <th scope="col">Type</th>
                                                                    <th scope="col">Model</th>
                                                                    <th scope="col">Meter ID</th>
                                                                    <th scope="col" hidden>PID</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{{meter?.serial_number}}</td>
                                                                    <td>{{meter?.meter_type}}</td>
                                                                    <td>{{meter?.model}}</td>
                                                                    <td>{{meter?.meter_number}}</td>
                                                                    <td hidden>{{meter?.meter_id}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <!-- File Upload Required for certain meter types -->
                                                        <ng-container *ngIf="meterTypeIDsRequireFileUpload.includes(meter.list_meter_type_id)">
                                                            <h6 class="text-primary small">&#8226; {{meter?.meter_type}} requires a printout of the {{meter?.meter_type}} report. Please add the report below in the file attachments/uploads area.</h6>
                                                        </ng-container>
                                                        <table class="table table-borderless gpu-inspections-table-middle mr-5 pb-4">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Ignore</th>
                                                                    <th class="w-px-200" scope="col">Meter Reading</th>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">Initial Reading</th>
                                                                    <th scope="col"></th>
                                                                    <th scope="col" *ngIf="meterTypeIDsIncludeGPM.includes(meter.list_meter_type_id)">GPM</th>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">Total(Acre-Feet)</th>
                                                                    <th class="w-px-400"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container *ngFor="let mInspection of meter?.inspections; let indexOfMeterInspectionElement=index;">
                                                                    <tr *ngIf="(mInspection.reporting_year >= gpuProd.year) && (mInspection.visible_to_producer == true)">
                                                                        <td>
                                                                            <input type="checkbox"
                                                                                   class="m-3"
                                                                                   [(ngModel)]="mInspection.ignore_reading"
                                                                                   [ngModelOptions]="{standalone: true}"
                                                                                   (change)="meterInspectionRowChange($event,gpuProd,meter,mInspection)" />
                                                                        </td>
                                                                        <td class="w-px-200">
                                                                            <div class="float-left d-inline-block mb-2">
                                                                                <input type="number" class="form-control"
                                                                                       [disabled]="gpuProd.list_production_status_id === this.ProductionStatus.submitted ? true : false"
                                                                                       [ngClass]="{'ng-invalid' : mInspection.error_message_meter_reading,'ng-invalid-input' : mInspection.error_message_meter_reading}"
                                                                                       [(ngModel)]="mInspection.meter_reading"
                                                                                       (change)="meterInspectionRowChange($event,gpuProd,meter,mInspection)"
                                                                                       (keyup)="meterInspectionRowChange($event,gpuProd,meter,mInspection)"
                                                                                       min="{{mInspection?.initial_meter_reading}}" />
                                                                            </div>
                                                                            <span class="input-error-text" ng-show="mInspection.error_message_meter_reading">{{mInspection.error_message_meter_reading}}</span>
                                                                        </td>

                                                                        <td>
                                                                            <div class="float-left d-inline-block text-center gpu-ir-big-bold">-</div>
                                                                        </td>
                                                                        <td class="w-px-200">
                                                                            <div class="float-left d-inline-block gpu-ir-big-bold">
                                                                                {{mInspection?.initial_meter_reading}}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div class="float-left d-inline-block text-center gpu-ir-big-bold"></div>
                                                                        </td>
                                                                        <td class="w-px-200" *ngIf="meterTypeIDsIncludeGPM.includes(meter.list_meter_type_id)">
                                                                            <div class="float-left d-inline-block mb-2">
                                                                                <input type="number" class="form-control"
                                                                                       [disabled]="gpuProd.list_production_status_id === this.ProductionStatus.submitted ? true : false"
                                                                                       [ngClass]="{'ng-invalid' : mInspection.error_message_gpm,'ng-invalid-input' : mInspection.error_message_gpm}"
                                                                                       [(ngModel)]="mInspection.gpm"
                                                                                       (change)="meterInspectionRowChange($event,gpuProd,meter,mInspection)"
                                                                                       (keyup)="meterInspectionRowChange($event,gpuProd,meter,mInspection)" />
                                                                            </div>
                                                                            <span class="input-error-text" ng-show="mInspection.error_message_gpm">{{mInspection.error_message_gpm}}</span>
                                                                        </td>

                                                                        <td>
                                                                            <div class="float-left d-inline-block text-center gpu-ir-big-bold">=</div>
                                                                        </td>
                                                                        <td class="w-px-200">
                                                                            <input disabled type="text"
                                                                                   class="form-control float-left mb-2"
                                                                                   [ngClass]="{'ng-invalid' : mInspection.error_message_acre_feet,'ng-invalid-input' : mInspection.error_message_acre_feet}"
                                                                                   [(ngModel)]="mInspection.acre_feet" />
                                                                            <span class="input-error-text" ng-show="mInspection.error_message_acre_feet">{{mInspection.error_message_acre_feet}}</span>
                                                                        </td>
                                                                        <td class="w-px-400"></td>
                                                                    </tr>

                                                                    <ng-container *ngIf="mInspection!.fileFormForFileData">
                                                                        <tr>
                                                                            <td colspan="8">
                                                                                <div class="container">
                                                                                    <div class="row justify-content-center">
                                                                                        <table class="table col-sm-11 mb-3">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th scope="col"></th>
                                                                                                    <th scope="col">Filename</th>
                                                                                                    <th scope="col">Size</th>
                                                                                                    <th scope="col">Updated</th>
                                                                                                    <th scope="col"></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr *ngFor="let mFile of mInspection!.filesUploadedList" class="border meter-file-row" (click)="fileRowClicked($event, meter, mInspection, mFile)">
                                                                                                    <th scope="row" class="p-2"><mat-icon class="material-icons">folder</mat-icon></th>
                                                                                                    <td class="p-2">{{mFile.name}}</td>
                                                                                                    <td class="p-2">{{mFile.size}}</td>
                                                                                                    <td class="p-2">{{mFile.updated}}</td>
                                                                                                    <td class="ms-auto p-2" *ngIf="gpuProd.list_production_status_id != this.ProductionStatus.submitted"><mat-icon (click)="deleteFileClicked($event, gpuProd, meter, mInspection, mFile)" class="material-icons f-l">delete</mat-icon></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    <ng-container *ngIf="mInspection.meter_inspection_id">
                                                                                        <div class="row justify-content-center" *ngIf="gpuProd.list_production_status_id != this.ProductionStatus.submitted">
                                                                                            <div class="col-sm-12">
                                                                                                <form *ngIf="mInspection!.fileFormForFileData" [formGroup]="mInspection!.fileFormForFileData">
                                                                                                    <mat-form-field appearance="outline" class="text-center w-100">
                                                                                                        <h4 class="">Click Here to upload files for this meter</h4>
                                                                                                        <!--<button type="button" class="btn btn-primary">Choose File</button>-->
                                                                                                        <ngx-mat-file-input id="mat-file-input-id-for-meter-{{meter.meter_id}}"
                                                                                                                            formControlName="file"
                                                                                                                            valuePlaceholder=" "
                                                                                                                            (change)="uploadDocument($event, gpuProd, meter, mInspection)"
                                                                                                                            [multiple]="false"
                                                                                                                            [disabled]=uploadOperationWaiting>
                                                                                                        </ngx-mat-file-input>
                                                                                                        <small> Allowed formats: {{ allowedFileFormats.join(',') }} Max file size. {{ maxFileSize }} </small>
                                                                                                    </mat-form-field>
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="!mInspection.meter_inspection_id">
                                                                                        <div class="row justify-content-center">
                                                                                            <h6 class="text-primary small">&#8226; Save the meter reading to enable file uploads.</h6>
                                                                                        </div>
                                                                                    </ng-container>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>

                                                                    <ng-container *ngIf="!(mInspection!.fileFormForFileData) && (mInspection.visible_to_producer) && (mInspection.reporting_year == searchYear)">
                                                                        <tr>
                                                                            <td colspan="8">
                                                                                <div class="row justify-content-center">
                                                                                    <h6 class="text-primary small">&#8226; Save the meter reading to enable file uploads</h6>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>


                                                                </ng-container>
                                                            </tbody>
                                                        </table>

                                                        <div>
                                                            <ng-container *ngFor="let s of meter?.section">
                                                                <div class="gpu-insp-text">Section {{s?.section}}, Block {{s?.block}}, Survey: {{s?.survey}}, County: {{s?.list_county_id}}</div>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="bg-light"
                                                     dir="rtl"
                                                     *ngIf="gpuProd.list_production_status_id != this.ProductionStatus.submitted">
                                                    <button class="m-3 mr-4 btn btn-outline-info bg-white"
                                                            (click)="saveMeterInspections($event, gpuProd)"
                                                            *ngIf="!gpuProd.list_production_status_is_ready_to_submit">
                                                        Save
                                                    </button>
                                                    <button class="m-3 mr-4 btn btn-outline-info bg-white"
                                                            (click)="submitGPUProductionReport($event, gpuProd)"
                                                            *ngIf="gpuProd.list_production_status_is_ready_to_submit">
                                                        Submit
                                                    </button>
                                                    <mat-spinner [diameter]="40" class="float-end m-3" *ngIf="gpuProd.operation_currently_running"></mat-spinner>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col me-3">
                <div class="text-center p-4 bg-light">
                    If you have discrepancies or inaccurate information with GPU's or Meters, please list these by email @ {{supportEmail}} or contact the District Office at {{supportPhoneNumber}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="helpModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Help</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                If you have discrepancies or inaccurate information with GPU's or Meters, please list these by email @ {{supportEmail}} or contact the District Office at {{supportPhoneNumber}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>