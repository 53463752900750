//export const API_URL = 'http://localhost:5012';          // The Flask Server when running via runserver.py locally
export const API_URL = '';  //'/chris_anderson_portal/backend/';  // The Flask Server via Apache WSGI
export const FORM_SERVICE_URL = 'https://northplainsgcd.mapfeeder.net/prod/';
export const POSTGREST_URL = 'https://northplainsgcd.mapfeeder.net/postgrest_prod';
export const LIST_METER_TYPE_ID_INCLUDE_GPM = [64, 65, 66]
export const LIST_METER_TYPE_ID_REQUIRE_FILE_UPLOAD = [65, 67, 68]
export const LIST_METER_TYPE_ID_ZERO_INITIAL_METER_READING = [67, 68, 65, 70, 71]
export const DEFAULT_PRODUCTION_YEAR = 2024
export const SUPPORT_PHONE_NUMBER = '(806) 935-6401'
export const SUPPORT_EMAIL = 'productionreporting@northplainsgcd.org'
export const LIST_VALID_UPLOAD_FILE_TYPES = ['.doc', '.docx', '.xls', '.xlsx', '.txt', '.rtf', '.pdf', '.jpg', '.jpeg', '.tiff', '.png']
export const FILE_UPLOAD_MAX_SIZE_IN_BYTES = 26214400
export const GCP_ANALYTICS_MEASUREMENT_ID = 'G-90M997YMRG'